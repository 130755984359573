import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
// import Swiper from "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js";

// additing listeners for inputs like matherial ui
const matherialInputs = document.querySelectorAll(".input-wrap input");
const matherialTextareas = document.querySelectorAll(".input-wrap textarea");

function setInputsListeners() {
  [...matherialInputs, ...matherialTextareas].forEach((input) => {
    input.addEventListener("input", ({ target }) => {
      const wrap = input.closest(".input-wrap");

      if (target.value.length) {
        wrap.classList.add("not-empty");
      } else {
        wrap.classList.remove("not-empty");
      }
    });
  });
}

setInputsListeners();

// inline style clearing
const contentElement = document.querySelector(".job__content.content-element");

if (contentElement) {
  const inlineStyleList = contentElement.querySelectorAll("style");

  inlineStyleList.forEach((item) => item.remove());

  const innerElementList = contentElement.querySelectorAll("*");

  innerElementList.forEach((item) => {
    item.removeAttribute("class");
    item.removeAttribute("style");
  });
}

AOS.init({
  once: true,
  duration: 500,
});

const isTablet = window.matchMedia("(max-width: 769px)").matches;

const header = document.getElementsByClassName(`header`)[0];
const burger = document.getElementsByClassName(`burger`)[0];

$(".js-custom-select").select2();

// $(".js-custom-select").each(function () {
//   console.log($(this));
//   $(this).select2();
// });

$(".js-custom-select").map(function () {
  $(this).select2();
});

burger.addEventListener("click", () => {
  header.classList.toggle("scrolled");

  if (window.scrollY === 0) {
    header.classList.remove("scrolled");
  }
});

if (window.scrollY !== 0) {
  header.classList.add("scrolled");
}

if ($(".archived-btn")) {
  $(".archived-btn").click(function () {
    $(".archived-btn").addClass("hide");
  });
}

// Hide and show Header when scroll up
function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = header.scrollHeight;

    if (window.scrollY > 0) {
      if (prevScrollPos > currentScrollPos) {
        header.style.top = 0;
        header.classList.add("scrolled");
      } else {
        header.style.top = `-${headerHeight + 3}px`;
      }
    }

    if (window.scrollY === 0) {
      header.classList.remove("scrolled");
    }

    prevScrollPos = currentScrollPos;
  };

  if (window.scrollY !== 0) {
    header.classList.add("scrolled");
  }
}

headerSticky();

// Job titles in placeholder
const heroPageInput = document.querySelector(
  ".heading-section__form .main-input"
);
if (heroPageInput) {
  let flag = true;
  const inputJobList = document.querySelector(".input-job-list");
  const label = document.querySelector(".hero-page-typeit");
  const jobTitlesArr = inputJobList.value
    .split(",")
    .filter((el) => el)
    .map((el) => {
      return el.charAt(0).toUpperCase() + el.slice(1);
    });

  window.onload = function () {
    if (heroPageInput.value) {
      heroPageInput.value = "";
    }
  };

  const typeEffect = new TypeIt(".hero-page-typeit", {
    waitUntilVisible: true,
    speed: 75,
    loop: true,
  });

  switch (jobTitlesArr.length) {
    case 1:
      typeEffect
        .type(jobTitlesArr[0])
        .pause(1000)
        .delete(jobTitlesArr[0].length)
        .go();
      break;

    case 2:
      typeEffect
        .type(jobTitlesArr[0])
        .pause(1000)
        .delete(jobTitlesArr[0].length)
        .type(jobTitlesArr[1])
        .pause(1000)
        .delete(jobTitlesArr[1].length)
        .go();
      break;

    case 3:
      typeEffect
        .type(jobTitlesArr[0])
        .pause(1000)
        .delete(jobTitlesArr[0].length)
        .type(jobTitlesArr[1])
        .pause(1000)
        .delete(jobTitlesArr[1].length)
        .type(jobTitlesArr[2])
        .pause(1000)
        .delete(jobTitlesArr[2].length)
        .go();
      break;

    case 4:
      typeEffect
        .type(jobTitlesArr[0])
        .pause(1000)
        .delete(jobTitlesArr[0].length)
        .type(jobTitlesArr[1])
        .pause(1000)
        .delete(jobTitlesArr[1].length)
        .type(jobTitlesArr[2])
        .pause(1000)
        .delete(jobTitlesArr[2].length)
        .type(jobTitlesArr[3])
        .pause(1000)
        .delete(jobTitlesArr[3].length)
        .go();
      break;

    case 5:
      typeEffect
        .type(jobTitlesArr[0])
        .pause(1000)
        .delete(jobTitlesArr[0].length)
        .type(jobTitlesArr[1])
        .pause(1000)
        .delete(jobTitlesArr[1].length)
        .type(jobTitlesArr[2])
        .pause(1000)
        .delete(jobTitlesArr[2].length)
        .type(jobTitlesArr[3])
        .pause(1000)
        .delete(jobTitlesArr[3].length)
        .type(jobTitlesArr[4])
        .pause(1000)
        .delete(jobTitlesArr[4].length)
        .go();
      break;
  }

  if (jobTitlesArr.length > 5) {
    typeEffect
      .type(jobTitlesArr[0])
      .pause(1000)
      .delete(jobTitlesArr[0].length)
      .type(jobTitlesArr[1])
      .pause(1000)
      .delete(jobTitlesArr[1].length)
      .type(jobTitlesArr[2])
      .pause(1000)
      .delete(jobTitlesArr[2].length)
      .type(jobTitlesArr[3])
      .pause(1000)
      .delete(jobTitlesArr[3].length)
      .type(jobTitlesArr[4])
      .pause(1000)
      .delete(jobTitlesArr[4].length)
      .go();
  }

  heroPageInput.addEventListener("focus", () => {
    if (flag) {
      label.style.display = "none";
      typeEffect.freeze();
      flag = false;
    }
  });

  heroPageInput.addEventListener("focusout", () => {
    if (!heroPageInput.value) {
      label.style.display = "";
      typeEffect.unfreeze();
      typeEffect.go();
      flag = true;
    }
  });
}

// Target _blank to all links in content-element
var contentLinks = document.querySelectorAll(".content-element a");
if (contentLinks.length > 0)
  contentLinks.forEach((link) => link.setAttribute("target", "_blank"));

const popupSitesRef = document.querySelectorAll(".popup-site");
if (popupSitesRef.length) {
  popupSitesRef.forEach((item) => {
    item.addEventListener("click", (e) => {
      if (e.target === item) {
        item.classList.remove("open");

        if(document.querySelector('.fixed-btn')) {
          const fixedBtnOpen = document.querySelector(".fixed-btn-open");
          const fixedBtnClose = document.querySelector(".fixed-btn-close");

          if(fixedBtnClose) {
            if(fixedBtnOpen) {
              fixedBtnOpen.style.opacity = "1";
              fixedBtnOpen.style.pointerEvents = "all";
            }

            fixedBtnClose.style.opacity = "0";
            fixedBtnClose.style.pointerEvents = "none";
          }
        }
      }
    });
  });
}

// Images in article
const articleContentBlock = document.querySelector(".article-content");
if (articleContentBlock) {
  const allImages = articleContentBlock.querySelectorAll(
    ".content-element img"
  );

  allImages.forEach((img, i) => {
    i % 2 === 0 ? img.classList.add("odd") : img.classList.add("even");
  });
}

// menu handlers
const mobHeader = document.querySelector(".mobile-header");

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

// $(".dropdown-toggle").click(function (e) {
//   if (isMobileMenuEnable) {
//     //close all opened sub menu
//     $(".menu-item.dropdown.active .dropdown-menu").slideUp({
//       complete: function () {
//         $(this).closest(".dropdown").removeClass("active");
//       },
//     });
//
//     //open current submenu
//     $(this).closest(".menu-item.dropdown").toggleClass("active");
//     if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
//       e.preventDefault();
//       $(this).next(".dropdown-menu").slideDown();
//     }
//   }
// });

if (header.querySelector(".menu-item.dropdown")) {
  const dropdownArr = document.querySelectorAll(
    ".mobile-header .mobile-menu .menu-item.dropdown"
  );
  // const dropdownToggleArr = header.querySelectorAll('.menu-link.dropdown-toggle');
  // const dropdownItemsArr = header.querySelectorAll('.dropdown-menu');

  dropdownArr.forEach((dropdown, i) => {
    const dropdownToggle = dropdown.querySelector(".dropdown-toggle");
    const dropdownArrow = dropdown.querySelector(".dropdown-toggle__icon");
    const dropdownItem = dropdown.querySelector(".dropdown-menu");
    dropdown.classList.add("no-opened");
    // console.log(dropdown);

    dropdown.addEventListener("click", (e) => {
      if (dropdown.classList.contains("no-opened")) {
        e.preventDefault();
        dropdown.classList.remove("no-opened");
      }

      if (mobHeader.querySelector(".menu-item.dropdown.active")) {
        mobHeader
          .querySelector(".menu-item.dropdown.active")
          .classList.add("no-opened");
        mobHeader
          .querySelector(".menu-item.dropdown.active")
          .classList.remove("active");
        mobHeader
          .querySelector(".dropdown-menu.active")
          .classList.remove("active");
      }

      dropdown.classList.add("active");
      dropdownItem.classList.add("active");
    });

    dropdownArrow?.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (mobHeader.querySelector(".menu-item.dropdown.active")) {
        mobHeader
          .querySelector(".menu-item.dropdown.active")
          .classList.add("no-opened");
        mobHeader
          .querySelector(".menu-item.dropdown.active")
          .classList.remove("active");
        mobHeader
          .querySelector(".dropdown-menu.active")
          .classList.remove("active");
      }
    });
  });
}

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".header").toggleClass("active");
  $(".mobile-header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");

  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(mobHeader)
    : scrollLock.enablePageScroll();
}

//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
// $(".header-close-wrapper").click(menuToggle); //menu toggles
const headerCloseWrapper = document.getElementsByClassName(
  "header-close-wrapper"
)[0];
if (headerCloseWrapper) {
  headerCloseWrapper.addEventListener("click", (e) => {
    let target = e.target;
    // console.log(target);

    if (!target.closest(".mobile-header")) {
      menuToggle();
    }
  });
}

// Tesla menu in second header
const secondHeader = document.querySelector(".header.white");
if (secondHeader) {
  const navbar = secondHeader.getElementsByClassName(`menu`)[0];

  const navbarElements = navbar.querySelectorAll(`.menu-item`);
  const spanElement = navbar.querySelector(`span`);
  const activeMenuElement = navbar.querySelector(`.menu-item.active`);

  function backgroundMenuPositionFunc(targetElement, flagMouseEnter) {
    const navbarPosition = navbar.getBoundingClientRect();
    const elementPosition = targetElement.getBoundingClientRect();

    let spanPositionLeftStart = elementPosition.left - navbarPosition.left;
    let spanWidthStart = elementPosition.width;

    if (flagMouseEnter) {
      spanElement.style.setProperty(
        "--span-transition",
        `0.5s cubic-bezier(0.75, 0, 0, 1)`
      );
    } else {
      spanElement.style.setProperty(
        "--span-transition",
        `opacity 0.5s ease, visibility 0s 0s`
      );
    }
    spanElement.style.setProperty("--width-span", `${spanWidthStart}px`);
    spanElement.style.setProperty(
      "--left-position-span",
      `${spanPositionLeftStart}px`
    );
  }

  if (activeMenuElement) {
    backgroundMenuPositionFunc(activeMenuElement, true);
    spanElement.classList.add("active");

    navbarElements.forEach((elem) => {
      elem.addEventListener("mouseenter", function (e) {
        backgroundMenuPositionFunc(e.target, true);
      });

      navbar.addEventListener("mouseleave", function (e) {
        backgroundMenuPositionFunc(activeMenuElement, true);
      });
    });
  } else {
    let flagMouseEnter = false;

    navbarElements.forEach((elem) => {
      elem.addEventListener("mouseenter", function (e) {
        backgroundMenuPositionFunc(e.target, flagMouseEnter);
        spanElement.classList.add("active");

        flagMouseEnter = true;
      });
    });

    navbar.addEventListener("mouseleave", function (e) {
      spanElement.classList.remove("active");
      flagMouseEnter = false;
      spanElement.style.setProperty(
        "--span-transition",
        `opacity 0.5s ease, visibility 0s 0.5s`
      );
    });
  }
}

// Parallax effect
function parallaxEffect(elements) {
  // Magic happens here
  function parallax(e) {
    let width = window.innerWidth / 2;

    let mouseX = e.clientX;
    let mouseY = e.clientY;

    let positionX = `${1 - (mouseX - width) * 0.005}%`;
    let positionY = `${1 - (mouseY - width) * 0.005}%`;

    elements.forEach((item, i) => {
      item.style.transform = `translate(${positionX}, ${positionY})`;
    });
  }

  document.addEventListener("mousemove", parallax);

  if (window.matchMedia("(max-width: 551px)").matches) {
    document.removeEventListener("mousemove", parallax);
  }
}
const parallaxItems = document.querySelectorAll(".parallax");
parallaxEffect(parallaxItems);

// for two section-bg parallax
const firstMainSection = document.querySelector(".first-section");
if (firstMainSection) {
  const statisticsBg = firstMainSection.querySelector(".statistics__bg-img");

  window.addEventListener("scroll", () => {
    window.scrollY > firstMainSection.clientHeight
      ? statisticsBg.classList.add("active")
      : statisticsBg.classList.remove("active");
  });
}

// document.addEventListener("DOMContentLoaded", function () {
//   const briefSection = document.querySelector(".brief");

//   if (briefSection) {
//     const articleList = document.querySelector(".brief__article-list");
//     const msnry = new Masonry(articleList, {
//       itemSelector: ".brief__article-item",
//       columnWidth: ".grid-sizer",
//       percentPosition: true,
//       gutter: 32,
//     });
//   }
// });

// show more leading section
// const textWithVideoBlock =
//   document.getElementsByClassName("text-with-video")[0];

// if (textWithVideoBlock) {
//   const textBlock = textWithVideoBlock.querySelector(
//     ".text-with-video__text-block"
//   );
//   const moreBtn = textWithVideoBlock.querySelector(
//     ".text-with-video__arrow-btn.mob"
//   );

//   moreBtn?.addEventListener("click", () => {
//     textBlock.classList.toggle("is-open");

//     if (textBlock.classList.contains("is-open")) {
//       moreBtn.innerHTML = `Show Less <i class="icon-arrow-down arrow-btn__icon"></i>`;
//     } else {
//       moreBtn.innerHTML = `Show More <i class="icon-arrow-down arrow-btn__icon"></i>`;
//     }
//   });
// }

// countries tabs
const countriesSection = document.getElementsByClassName("countries")[0];

if (countriesSection) {
  const tabs = countriesSection.querySelectorAll(".countries__tab");
  const tabsContent = countriesSection.querySelectorAll(
    ".countries__tab-content"
  );

  const activeTab = countriesSection.querySelector(".countries__tab.active");

  const tabsContentSwiper = new Swiper(".countries__tab-content-swiper", {
    spaceBetween: 0,
    slidesPerView: 1,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  const tabSlider = new Swiper(".countries__tab-list-mob", {
    slidesPerView: "auto",
    enabled: true,
    centeredSlides: true,
    navigation: {
      nextEl: ".countries__tab-list-mob-nav.next",
      prevEl: ".countries__tab-list-mob-nav.prev",
    },
    breakpoints: {
      768: {
        enebled: false,
      },
    },
  });

  tabsContentSwiper.controller.control = tabSlider;
  tabSlider.controller.control = tabsContentSwiper;

  const setBgPosition = (element, leftBgSelector, rightBgSelector) => {
    const leftBg = document.querySelector(leftBgSelector);
    const rightBg = document.querySelector(rightBgSelector);

    leftBg.style.left = element.offsetLeft + "px";
    rightBg.style.left = element.offsetLeft + element.offsetWidth + "px";
  };

  const setActiveTab = (item) => {
    const { slideId } = item.dataset;

    item.classList.add("active");

    tabsContentSwiper.slideTo(Number(slideId));
  };

  if (!isTablet) {
    setBgPosition(
      activeTab,
      ".countries__tabs-left-bg",
      ".countries__tabs-right-bg"
    );

    const handleTabClick = (item) => {
      tabs.forEach((item) => {
        item.classList.remove("active");
      });

      setActiveTab(item);

      setBgPosition(
        item,
        ".countries__tabs-left-bg",
        ".countries__tabs-right-bg"
      );
    };

    tabs.forEach((item) => {
      const itemBtn = item.querySelector(".countries__tab-btn");
      itemBtn.addEventListener("click", () => {
        handleTabClick(item);
      });
    });
  }
}

window.addEventListener("DOMContentLoaded", (event) => {
  // our values section
  const ourValues = document.querySelector(".our-values");

  if (ourValues) {
    const isMob = window.matchMedia("(max-width: 500px)").matches;
    const valueItems = ourValues.querySelectorAll(".values-list__item");
    let gridCard = takeGridSet(valueItems);
    let activeIndex = null;
    let trueArr = [];
    let { offsetWidth: valueListBlockWidth } =
      ourValues.querySelector(".values-list");
    let trueIndex = 0;

    if (isMob) {
      valueItems.forEach((item, idx) => {
        item.addEventListener("click", () => {
          // Якщо карточка на яку ми натискаємо вже активна, робимо її неактивною
          //  і виходимо з функції
          if (idx === activeIndex) {
            item.classList.remove("active");
            activeIndex = null;
            return;
          }

          // Якщо активна карточка вже є, робимо її неактивною, а ту на яку
          // натиснули робимо активною
          if (activeIndex !== null) {
            valueItems[activeIndex].classList.remove("active");
          }

          activeIndex = idx;

          item.classList.add("active");
        });
      });
    } else {
      valueItems.forEach((item, idx) => {
        item.addEventListener("click", () => {
          // Якщо карточка на яку ми натискаємо вже активна, робимо її
          // неактивною і перераховуємо ширину карток в рядку і виходимо з функції
          if (idx === activeIndex) {
            item.classList.remove("active");

            for (let i = 0; i < trueArr.length; i++) {
              trueArr[i].style.flexBasis = `${Math.floor(
                valueListBlockWidth / trueArr.length - 2
              )}px`;
              activeIndex = null;
            }
            return;
          }

          // якщо вже є активна картка робимо її не активною, а ту на яку
          // натиснули робюимо активною і перераховуємо ширини карток в рядку
          if (activeIndex !== null) {
            valueItems[activeIndex].classList.remove("active");

            for (let i = 0; i < trueArr.length; i++) {
              trueArr[i].style.flexBasis = `${Math.floor(
                valueListBlockWidth / trueArr.length - 2
              )}px`;
            }
          }

          activeIndex = idx;

          item.classList.add("active");

          let accum = 0;

          for (let i = 0; i < gridCard.length; i++) {
            accum += gridCard[i].length;

            if (idx < accum) {
              trueIndex =
                (idx - (accum - gridCard[i].length)) % gridCard[i].length;
              trueArr = gridCard[i];
              break;
            }
          }

          let cardWidth = valueListBlockWidth / trueArr.length;

          for (let i = 0; i < trueArr.length; i++) {
            if (i === trueIndex) {
              trueArr[i].style.flexBasis = `${Math.floor(cardWidth * 1.3)}px`;
            } else {
              trueArr[i].style.flexBasis = `${Math.floor(
                cardWidth - (cardWidth * 0.3) / (trueArr.length - 1) - 2
              )}px`;
            }
          }
        });
      });
    }

    window.addEventListener("resize", () => {
      const activeItem = ourValues.querySelector(".values-list__item.active");

      activeItem?.classList.remove("active");

      activeIndex = null;

      // записуємо нову ширину блоку для корректного калькулювання ширини карточки
      valueListBlockWidth = ourValues.querySelector(".values-list").offsetWidth;

      gridCard = takeGridSet(valueItems);

      valueItems.forEach((item) => (item.style.flexBasis = ""));
    });
  }

  function takeGridSet(valueItems) {
    let gridCard = [];
    let buffer = [];

    for (let i = 0; i < valueItems.length; i++) {
      if (
        i > 0 &&
        valueItems[i].getBoundingClientRect().top !==
          valueItems[i - 1].getBoundingClientRect().top
      ) {
        gridCard.push(buffer);
        buffer = [];
      }

      if (i === valueItems.length - 1) {
        gridCard.push(buffer);
      }

      buffer.push(valueItems[i]);
    }
    return gridCard;
  }

  // const counts = document.querySelectorAll(".count-up");

  // if (counts.length) {
  //   console.log(counts);
  //   const options = { enableScrollSpy: true };
  //   let idNumber = 1;
  //   counts.forEach((count) => {
  //     console.dir(count.dataset);
  //     const id = `count-up-${idNumber}`,
  //       value = parseInt(count.innerHTML);
  //     new CountUp(id, value, {
  //       enableScrollSpy: true,
  //       ...count.dataset,
  //     });
  //     idNumber++;
  //   });
  // }
});

// Choose File
var fileItem = document.querySelectorAll(".file-item");
if (fileItem.length) {
  fileItem.forEach((item) => {
    const filePreview = item.querySelector(".file-text");
    const fileInput = item.querySelector(".file-input");

    fileInput.addEventListener("change", () => {
      uploadFile(fileInput.files[0], filePreview);
    });
  });

  function uploadFile(file, filePreview) {
    const fileRead = new FileReader();
    filePreview.setAttribute("placeholder", `${file.name}`);
  }
}

// Toggle button function
function toggleButton(button, openText, closeText) {
  if (button.classList.contains("open")) {
    button.querySelector("span").innerHTML = closeText;
  } else {
    button.querySelector("span").innerHTML = openText;
  }
}

// Five minute article
const articleSection = document.querySelector(".five-minutes");
if (articleSection) {
  const openButton = articleSection.querySelector(".five-minutes__btn");
  const closeButton = articleSection.querySelector(".article-block__close");
  const closeText = articleSection.querySelector(".close-text");
  const articleContent = articleSection.querySelector(".article-block");

  const archiveOpenButton = articleSection.querySelector(
    ".five-minutes__btn-archived"
  );
  const archiveBlock = articleSection.querySelector(".archive-block");

  function handleToggleArticle() {
    archiveOpenButton?.classList.remove("open");

    if (archiveBlock) {
      closeAllArchived();
      archiveBlock.classList.remove("open");
      archiveBlock.style.maxHeight = null;
    }

    openButton.classList.toggle("open");
    articleContent.classList.toggle("open");

    articleContent.style.maxHeight = null;

    if (articleContent.classList.contains("open")) {
      articleContent.style.maxHeight = articleContent.scrollHeight + "px";
    }

    toggleButton(
      openButton,
      "VIEW FULL ‘5 minutes with…’",
      "CLOSE ‘5 minutes with…’"
    );
  }

  function handleToggleArchive() {
    closeAllArchived();
    openButton.classList.remove("open");
    articleContent.classList.remove("open");
    articleContent.style.maxHeight = null;

    archiveOpenButton.classList.toggle("open");
    archiveBlock.classList.toggle("open");

    archiveBlock.style.maxHeight = null;

    if (archiveBlock?.classList.contains("open")) {
      archiveBlock.style.maxHeight = archiveBlock.scrollHeight + "px";
    }
  }

  archiveOpenButton?.addEventListener("click", handleToggleArchive);

  openButton.addEventListener("click", handleToggleArticle);

  closeButton.addEventListener("click", handleToggleArticle);

  closeText.addEventListener("click", handleToggleArticle);

  const archived5Mins = archiveBlock?.querySelectorAll(".archive-block__item");

  function closeAllArchived(ignoredElement) {
    archived5Mins.forEach((item) => {
      const openButton = item.querySelector(".five-minutes__btn");
      const articleContent = item.querySelector(".article-block");

      if (ignoredElement === item) return;

      openButton.classList.remove("open");
      articleContent.classList.remove("open");

      articleContent.style.maxHeight = null;
    });
  }

  if (archived5Mins) {
    archived5Mins.forEach((item) => {
      const openButton = item.querySelector(".five-minutes__btn");
      const closeButton = item.querySelector(".article-block__close");
      const closeText = item.querySelector(".close-text");
      const articleContent = item.querySelector(".article-block");

      function handleToggleArticle() {
        closeAllArchived(item);
        openButton.classList.toggle("open");
        articleContent.classList.toggle("open");

        articleContent.style.maxHeight = null;

        if (articleContent.classList.contains("open")) {
          articleContent.style.maxHeight = articleContent.scrollHeight + "px";

          if (archiveBlock) {
            archiveBlock.style.maxHeight =
              articleContent.scrollHeight + archiveBlock.scrollHeight + "px";
          }
        }

        toggleButton(
          openButton,
          "VIEW FULL ‘5 minutes with…’",
          "CLOSE ‘5 minutes with…’"
        );
      }

      openButton.addEventListener("click", handleToggleArticle);

      closeButton.addEventListener("click", handleToggleArticle);

      closeText.addEventListener("click", handleToggleArticle);
    });
  }

  window.addEventListener("resize", () => {
    if (articleContent.classList.contains("open")) {
      articleContent.style.maxHeight = articleContent.scrollHeight + "px";
    }

    if (archiveBlock) {
      if (archiveBlock.classList.contains("open")) {
        archiveBlock.style.maxHeight = archiveBlock.scrollHeight + "px";
      }
    }
  });

  if (archiveBlock) {
    const swiperNav = archiveBlock.querySelector(".archive-block__swiper-nav");

    const archivedSwiper = new Swiper(".archive-block__swiper", {
      slidesPerView: 1,
      spaceBetween: 50,
      rewind: true,
      navigation: {
        nextEl: ".archive-block__swiper-btn.next",
        prevEl: ".archive-block__swiper-btn.prev",
      },
      on: {
        init: (e) => {
          swiperNav.style.top = e.slides[e.realIndex].scrollHeight / 2 + "px";
        },
        slideChange: (e) => {
          closeAllArchived();
          swiperNav.style.top = e.slides[e.realIndex].scrollHeight / 2 + "px";
        },
      },
    });

    window.addEventListener("resize", () => {
      swiperNav.style.top =
        archivedSwiper.slides[archivedSwiper.realIndex].scrollHeight / 2 + "px";
    });
  }
}

function setPlyr() {
  const players = document.querySelectorAll(".player");
  players.forEach((playerItem) => {
    const playerDOM = playerItem.querySelector("#player");
    const player = new Plyr(playerDOM, {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "pip",
        "airplay",
        "fullscreen",
      ],
    });
    const plyr = playerItem.querySelector(".plyr");
    const playBtn = playerItem.querySelector(
      ".text-with-video__video-play-btn"
    );
    plyr.classList.add("!absolute", "inset-0", "w-full", "h-full");

    playBtn.addEventListener("click", () => {
      player.play();
    });

    player.on("pause", () => {
      playBtn.style.display = "block";
    });

    player.on("play", () => {
      playBtn.style.display = "none";
    });
  });
}

setPlyr();

// Products cards
const productsSection = document.querySelector(".products");
if (productsSection && window.matchMedia("(max-width: 551px)").matches) {
  const productWrap = productsSection.querySelector(".products-list");
  const productList = [
    ...productsSection.querySelectorAll(".products-list__item"),
  ];
  const openButton = productsSection.querySelector(".products-btn");

  let contentHeight = productList
    .filter((item, i) => i < 3)
    .reduce((acc, item) => acc + item.scrollHeight, 0);

  if (productList.length > 3) {
    productWrap.style.maxHeight = contentHeight + 40 + "px";
  }

  openButton.addEventListener("click", () => {
    openButton.classList.toggle("open");

    if (openButton.classList.contains("open")) {
      productWrap.style.maxHeight = productWrap.scrollHeight + "px";
    } else {
      productWrap.style.maxHeight = contentHeight + 60 + "px";
    }

    toggleButton(openButton, "Open Products", "Close Products");
  });

  window.addEventListener("resize", () => {
    contentHeight = productList
      .filter((item, i) => i < 3)
      .reduce((acc, item) => acc + item.scrollHeight, 0);

    if (productList.length > 3) {
      productWrap.style.maxHeight = contentHeight + 40 + "px";
    }
  });
}

// ------- Sliders ------- //

// Testimonials Swiper
const testimonialsSection = document.querySelector(".testimonials-section");
if (testimonialsSection) {
  const testimonialSwiper = MetaSwiper(".testimonials-swiper", {
    observer: true,
    slidesPerView: "auto",
    spaceBetween: 50,
    centeredSlides: true,
    loop: true,
    speed: 800,

    // Navigation
    navigation: {
      nextEl: ".testimonials-btn.swiper-button-next",
      prevEl: ".testimonials-btn.swiper-button-prev",
    },

    // Pagination
    pagination: {
      clickable: true,
      dynamicMainBullets: 4,
      dynamicBullets: true,
      el: ".testimonials-swiper .swiper-pagination",
    },

    // Autoplay
    autoplay: {
      enabled: true,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  // let swiperStatus = false;
  //
  // const options = {
  //   threshold: 0.6,
  // };
  //
  // const callbackSwiperToggleAutoplay = function (entries, observer) {
  //   entries.forEach((elem) => {
  //     if (elem.target.classList.contains("testimonials-section")) {
  //       swiperStatus = elem.isIntersecting;
  //     }
  //   });
  //
  //   if (swiperStatus) {
  //     testimonialSwiper.autoplay.start();
  //     testimonialSwiper.update();
  //   } else {
  //     testimonialSwiper.autoplay.stop();
  //     testimonialSwiper.update();
  //   }
  // };
  //
  // const observer = new IntersectionObserver(
  //     callbackSwiperToggleAutoplay,
  //     options
  // );
  //
  // observer.observe(testimonialsSection);
}

const quotesSection = document.querySelector(".quotes");

if (quotesSection) {
  const quotesSwiper = new Swiper(".quotes__slider", {
    loop: true,
    navigation: {
      nextEl: ".quotes__slider-btn.next",
      prevEl: ".quotes__slider-btn.prev",
    },
    pagination: {
      clickable: true,
      el: ".quotes__slider-pagination",
    },
  });
}

const jobSearchFilterMob = document.querySelector(".jobs-search__filters-mob");

if (jobSearchFilterMob) {
  const filterSwiper = new Swiper(".jobs-search__filters-swiper", {
    spaceBetween: 30,
    slidesPerView: "auto",
    freeMode: true,
    navigation: {
      nextEl: ".jobs-search__filters-swiper-btn.next",
      prevEl: ".jobs-search__filters-swiper-btn.prev",
    },
  });
}

const jobSearchArticleListMob = document.querySelector(
  ".jobs-search__articles-list-wrap.mob"
);

if (jobSearchArticleListMob) {
  const articleListSwiper = new Swiper(
    ".jobs-search__articles-list-wrap.mob .jobs-search__articles-list",
    {
      spaceBetween: 30,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".jobs-search__filters-swiper-btn.next",
        prevEl: ".jobs-search__filters-swiper-btn.prev",
      },
    }
  );
}

window.addEventListener("load", videoScroll);
window.addEventListener("scroll", videoScroll);

// Instagram swiper
const socialsSection = document.querySelector(".feed-socials");
if (socialsSection) {
  const socialsSwiper = new Swiper(".insta-slider", {
    slidesPerView: "auto",

    grid: {
      rows: 2,
    },

    slidesPerColumn: 3,

    spaceBetween: 16,

    navigation: {
      nextEl: ".insta-slider__btn",
    },
  });
}

function videoScroll() {
  if (document.querySelectorAll("video[autoplay]").length > 0) {
    let windowHeight = window.innerHeight,
      videoEl = document.querySelectorAll("video[autoplay]");

    for (let i = 0; i < videoEl.length; i++) {
      let thisVideoEl = videoEl[i],
        videoHeight = thisVideoEl.clientHeight,
        videoClientRect = thisVideoEl.getBoundingClientRect().top;

      if (
        videoClientRect <= windowHeight - videoHeight * 0.5 &&
        videoClientRect >= 0 - videoHeight * 0.5
      ) {
        thisVideoEl.play();
      } else {
        thisVideoEl.pause();
      }
    }
  }
}

const bannersSwiperDOM = document.querySelector(".banners-slider");

if (bannersSwiperDOM) {
  const slidesCount = bannersSwiperDOM.querySelectorAll(".swiper-slide").length;

  const bannersSwiper = new Swiper(bannersSwiperDOM, {
    slidesPerView: 1,
    spaceBetween: 16,
    rewind: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    loopedSlides: 6,
    navigation: {
      prevEl: ".banners-swiper__btn.prev",
      nextEl: ".banners-swiper__btn.next",
    },
    // breakpoints: {
    //   501: {
    //     slidesPerView: 2,
    //     loop: slidesCount > 2,
    //   },
    //   1024: {
    //     slidesPerView: 3,
    //     loop: slidesCount > 3,
    //   },
    // },
  });
}

// BOLD Lottie init (Має бути в самому кінці файлу)
// lottie.loadAnimation({
//   container: document.getElementById(`bold-credits`),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: site_dir + "public/lottie/BOLD-white-logo.json",
// });

$(document).ready(function () {
  const opportunitiesSlider = document.getElementsByClassName(
    "opportunities-slider"
  )[0];
  if (opportunitiesSlider) {
    const slidesCount =
      opportunitiesSlider.querySelectorAll(".swiper-slide").length;

    const opportunitiesSwiper = new Swiper(".opportunities-slider", {
      spaceBetween: 50,
      slidesPerView: 1,
      speed: 1000,
      allowTouchMove: slidesCount <= 3 && window.innerWidth > 1025,
      loop: slidesCount > 1,
      navigation: {
        nextEl: ".opportunities-button-next",
        prevEl: ".opportunities-button-prev",
      },
      effect: "creative",
      creativeEffect: {
        prev: {
          opacity: 0,
          translate: ["-100%", 0, 0],
          scale: 0.7,
        },
        next: {
          opacity: 0,
          translate: ["100%", 0, 0],
          scale: 0.7,
        },
      },
    });
  }

  let newsListSwiperBlock = document.querySelector(".news-list-archive-swiper");
  if (newsListSwiperBlock) {
    let newsListSwiper = newsListSwiperBlock.querySelector(".swiper");
    let nextEl = newsListSwiperBlock.querySelector(".swiper-button-next");
    let prevEl = newsListSwiperBlock.querySelector(".swiper-button-prev");
    let slidesCount = newsListSwiper.querySelectorAll(".swiper-slide").length;
    const swiper = new Swiper(newsListSwiper, {
      slidesPerView: 3,
      speed: 1000,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
          spaceBetween: 25,
          loop: slidesCount > 1,
        },
        1026: {
          slidesPerView: 3,
          spaceBetween: 25,
          loop: slidesCount > 3,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 55,
          loop: slidesCount > 3,
        },
        1400: {
          slidesPerView: 3,
          spaceBetween: 70,
          loop: slidesCount > 3,
        },
      },
    });
  }
  //show hide archived news swiper
  $("body").on("click", ".toggle-list-archive-swiper", function () {
    $(this).parent().next(".news-list-archive-swiper").stop().slideToggle(800);
  });
}); //end of ready

const valuesSwiperDOM = document.querySelector(".values-list-swiper");

if (valuesSwiperDOM) {
  const swiper = new Swiper(valuesSwiperDOM, {
    slidesPerView: 1,
    navigation: {
      prevEl: ".values-list-swiper__btn.prev",
      nextEl: ".values-list-swiper__btn.next",
    },
  });
}

const jobFilterWrap = document.querySelector(".jobs-search__filter-list");

if (jobFilterWrap) {
  const filterGroupItems = jobFilterWrap.querySelectorAll(
    ".jobs-search__filter-item"
  );

  filterGroupItems.forEach((groupItem) => {
    const groupItems = groupItem.querySelectorAll(
      ".jobs-search__filter-option-wrap"
    );
    const filterResult = document.querySelector(".jobs-search__filter-result");

    groupItems.forEach((item) => {
      const itemCheckbox = item.querySelector(
        ".jobs-search__filter-option-checkbox"
      );

      if (itemCheckbox.checked) {
        const { textContent: itemTitle } = item.querySelector(
          ".jobs-search__filter-option"
        );
        const elemId = getElemIdFromTitle(itemTitle);

        createResultElement(
          elemId,
          itemTitle,
          itemCheckbox.name,
          itemCheckbox.value,
          itemCheckbox
        );
      }

      item.addEventListener("change", (e) => {
        const { textContent: itemTitle } = item.querySelector(
          ".jobs-search__filter-option"
        );
        const elemId = getElemIdFromTitle(itemTitle);

        const { name, value, checked } = e.target;

        if (checked) {
          createResultElement(elemId, itemTitle, name, value, e.target);
        } else {
          removeResultElement(elemId);
        }
      });
    });

    function createResultElement(
      elemId,
      title,
      filterTitle,
      value,
      checkboxRef
    ) {
      const filterResDiv = document.createElement("DIV");
      const filterRemoveBtn = document.createElement("BUTTON");

      filterResDiv.classList.add("jobs-search__filter-result-item");
      filterResDiv.id = elemId;
      filterResDiv.textContent = title;

      filterRemoveBtn.classList.add("icon-close");
      filterRemoveBtn.classList.add("jobs-search__filter-result-item-remove");
      filterRemoveBtn.type = "button";
      filterRemoveBtn.dataset.name = filterTitle;
      filterRemoveBtn.dataset.value = value;

      function handleRemoveElement() {
        checkboxRef.checked = false;
        checkboxRef.onchange();
        removeResultElement(elemId);
        filterRemoveBtn.removeEventListener("click", handleRemoveElement);
      }

      filterRemoveBtn.addEventListener("click", handleRemoveElement);

      filterResDiv.appendChild(filterRemoveBtn);
      filterResult.appendChild(filterResDiv);
    }

    function removeResultElement(elemId) {
      const elemRemove = document.getElementById(elemId);

      elemRemove.remove();
    }

    function getElemIdFromTitle(title) {
      return title.toLowerCase().trim().split(" ").join("-");
    }
  });
}

if(document.querySelector('.fixed-btn')) {
  const fixedBtnOpen = document.querySelector(".fixed-btn-open");
  const fixedBtnClose = document.querySelector(".fixed-btn-close");

  if(fixedBtnClose) {
    fixedBtnClose.addEventListener("click", function() {
      if(fixedBtnOpen) {
        fixedBtnOpen.style.opacity = "1";
        fixedBtnOpen.style.pointerEvents = "all";
      }

      fixedBtnClose.style.opacity = "0";
      fixedBtnClose.style.pointerEvents = "none";
      closeCustomPopup('#charity_popup');
    })
  }
}

